<template>
   <div class="container card">
            <div class="table-wrapper">
               <div class="table-title">
                    <div class="row">
                    <div class="col-sm-12">
                        <h2>All <b>Certificates</b></h2> <button data-toggle="modal"  data-target="#exampleModal" class="btn cert btn-danger float-right">Issue New Cert</button>
                    </div>
                    
                </div>
               </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> Issue A Certificate of Origin</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   <div class="form-group">
                    <label for="created_by_id"> Created By</label>
                    <input type="text" class="form-control">
                   </div>
                   <div class="form-group">
                    <label for="importer_afcfta_number"> Importer afcfta number</label>
                    <input type="text" class="form-control">
                   </div>
                   <div class="form-group">
                    <label for="exporter_name"> Exporter Name</label>
                    <input type="text" class="form-control">
                   </div>
                   <div class="form-group">
                    <label for="importer_address"> Importer Address</label>
                    <input type="text" class="form-control">
                   </div>
                   <div class="form-group">
                    <label for="products">Products</label>
                    <input type="text" class="form-control">
                   </div>
                   <div class="form-group">
                    <label for="exporter_address">Exporter Address</label>
                    <input type="text" class="form-control">
                   </div>
                   <div class="form-group">
                    <label for="certification_statement"> certification statement</label>
                  <textarea name="certification_statement"  cols="3"  class="form-control"></textarea>
                   </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger">Submit</button>
                </div>
                </div>
            </div>
            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>
                            <span>
                                <input type="checkbox" id="selectAll">
                                <label for="selectAll"></label>
                            </span>  
                        </th>
                        <th>ID</th>
                        <th>Exporter Name</th>
                        <th>Exporter Address</th>
                        <th>Importer Afcfta Number</th>
                        <th>Importer Address</th> 
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span class="custom-checkbox">
                                <input type="checkbox" id="checkbox1" name="option[]" value="1">
                                <label for="checkbox1"></label>
                            </span>
                        </td>
                        <td>1</td>
                        <td>Bob Alaska</td>
                        <td>Lome, Togo</td>
                        <td>2039438796</td>
                        <td>Accra, Ghana</td>
                        <td>
                        <a href="" class="btn btn-success" data-toggle="modal"><i class="fa  fa-eye" aria-hidden="true" data-toggle="tooltip" title="Edit"></i></a>
                        </td>
                    </tr>  
                   
                </tbody>
            </table>
            <!-- pagination -->
            <div class="clearfix">
                <div class="hint-text">Showing <b>15 </b>out of <b>entries</b></div>
                <ul class="pagination">
                    <li class="page-item"><a href="#" class="page-link">Previous</a></li>
                    <li class="page-item"><a href="#" class="page-link">1</a> </li>
                    <li class="page-item"><a href="#" class="page-link">2</a> </li>
                    <li class="page-item"><a href="#" class="page-link">3</a> </li>
                    <li class="page-item"><a href="#" class="page-link">4</a> </li>
                    <li class="page-item"><a href="#" class="page-link">Next</a></li>
                </ul>
            </div>
        </div>    
</template>

<script>
import {mapState} from 'vuex'

    export default {
        computed:mapState(['products']),
        created(){
            this.$store.dispatch('fetchAllProducts')
        }
       
    }
</script>

<style  scoped>
.table-wrapper{
    background: #fff;
    padding: 20px 25px;
    margin: 30px 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title{
    padding-bottom: 15px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    margin: 5px 0 0;
    font-size: 24px;
}

.pagination{
    float: right;
    margin: 0 0 5px;
}

.pagination li a{
    border: none;
    font-size: 14px;
    min-width: 30px;
    min-height: 30px;
    color: #999;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 1px !important;
    text-align: center;
    padding: 0 6px;
}
.cert{
    padding-top: -70px;
}
</style>